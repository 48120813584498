/** @jsx jsx */
import { jsx, Styled, Flex } from 'theme-ui';
import { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import Link from '../components/Link';
import * as yup from 'yup';
import * as api from '../utils/api';

import { goNext, saveContractNumber } from '../state/pickupOrder';
import { showNotification } from '../state/notifications';

import FullHeightColumn from '../components/FullHeightColumn';
import ContractNumberForm from './ContractNumberForm';

const ContractNumberFormik = withFormik({
  displayName: 'ContractNumberForm',
  mapPropsToValues: ({ contractNumber }) =>
    Object.assign({
      contractNumber: contractNumber || '',
    }),
  validationSchema: ({ translate }) => {
    const invalidNumber = translate('form.invalidNumber');
    return yup.object().shape({
      contractNumber: yup
        .number()
        .typeError(invalidNumber)
        .integer(invalidNumber)
        .positive(invalidNumber)
        .required(translate('form.requiredField')),
    });
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
})(ContractNumberForm);

export default function ContractNumber() {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const contractNumber = useSelector(state => state.pickupOrder.contractNumber);

  const componentIsMounted = useRef(null);
  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  });

  const onSubmit = useCallback(
    async ({ contractNumber }, { setSubmitting, setFieldError }) => {
      try {
        const isValid = await api.checkContractNumber(contractNumber);
        if (isValid) {
          dispatch(saveContractNumber(contractNumber));
          dispatch(goNext());
        } else {
          setFieldError(
            'contractNumber',
            translate('pickupOrder.contractNumber.invalidNumber')
          );
        }
      } catch (error) {
        dispatch(showNotification('genericApiError'));
      }
      if (componentIsMounted.current) {
        setSubmitting(false);
      }
    },
    [dispatch, translate]
  );

  const formProps = { onSubmit, contractNumber, translate };
  const salesLink = '/myynnin-yhteydenottolomake';

  return (
    <FullHeightColumn>
      <Styled.h1>{translate('pickupOrder.contractNumber.title')}</Styled.h1>
      <Styled.p>
        {translate('pickupOrder.contractNumber.description.1')}
      </Styled.p>
      <Styled.p>
        {translate('pickupOrder.contractNumber.description.2')}
      </Styled.p>
      <Styled.p>
        {translate('pickupOrder.contractNumber.description.3')}{' '}
        <Link to={salesLink}>
          {translate('pickupOrder.contractNumber.description.3.linkText')}
        </Link>
        .
      </Styled.p>
      <Styled.p>
        {translate('pickupOrder.contractNumber.description.4')}
      </Styled.p>
      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <ContractNumberFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
}
