import React from 'react';
import { connect } from 'react-redux';

import { PAGE } from '../state/pickupOrder';

import { Layout, Container } from '../components';
import ContractNumber from '../pickupOrder/ContractNumber';
import Contact from '../pickupOrder/Contact';
import PickupTime from '../pickupOrder/PickupTime';
import PickupItems from '../pickupOrder/PickupItems';
import ConfirmOrder from '../pickupOrder/ConfirmOrder';
import OrderDone from '../pickupOrder/OrderDone';
import * as analytics from '../utils/analytics';

class PickupOrder extends React.Component {
  componentDidMount() {

  }
  render() {
    analytics.setPageCategory(this, 'paketit');
    const { paths } = this.props.pageContext;
    const { page } = this.props;
    return (
      <Layout paths={paths} locale={this.props.pageContext.locale || 'en'}>
        <Container sx={{ maxWidth: 1024 }}>
          {(() => {
            switch (page) {
              case PAGE.CONTRACT_NUMBER:
                return <ContractNumber />;
              case PAGE.COMPANY_INFO:
                return <Contact />;
              case PAGE.PICKUP_TIME:
                return <PickupTime />;
              case PAGE.PICKUP_ITEMS:
                return <PickupItems />;
              case PAGE.CONFIRM_ORDER:
                return <ConfirmOrder />;
              case PAGE.ORDER_DONE:
                return <OrderDone />;
              default:
                return <h1>Virhe</h1>;
              }
          })()}
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  page: (state.pickupOrder || {}).page
});

export default connect(
  mapStateToProps
)(PickupOrder);