import React from 'react';
import { Styled } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import FullHeightColumn from '../components/FullHeightColumn';

export default function OrderDone() {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <FullHeightColumn>
      <Styled.h1>{translate('pickupOrder.done.title')}</Styled.h1>
      <p>{translate('pickupOrder.done.description')}</p>
    </FullHeightColumn>
  );
}
