import React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Box, Styled, Flex } from 'theme-ui';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { goNext, goBack, saveItems } from '../state/pickupOrder';

import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import PickupItemsForm from './PickupItemsForm';

const PickupItemsFormik = withFormik({
  displayName: 'PickupItemsForm',
  mapPropsToValues: ({ items }) => {
    if (items.length > 0) {
      return {
        items: items.map(item => ({ ...item })),
      };
    } else {
      return {
        items: [{ name: 'packet', amount: '', weight: '', shipmentNumbers: [] }],
      };
    }
  },
  validate: (values, { translate }) => {
    const itemErrors = values.items.map(item => {
      const errors = {};
      if (!item.name) {
        errors.name = translate('form.requiredField');
      }
      const amount = +item.amount;
      if (!amount || amount <= 0 || Math.round(amount) != amount) {
        errors.amount = translate('form.invalidAmount');
      }
      const weight = +item.weight;
      if (!weight || weight <= 0) {
        errors.weight = translate('form.invalidAmount');
      }
      if (item.name === 'packet') {
        if (!['yes', 'no'].includes(item.hasAddressCards)) {
          errors.hasAddressCards = translate('form.requiredField');
        }
      }
      return errors;
    });
    if (itemErrors.every(e => Object.keys(e).length === 0)) {
      return {};
    } else {
      return {
        items: itemErrors,
      };
    }
  },
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(PickupItemsForm);

export default function ContactPerson() {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const items = useSelector(state => state.pickupOrder.items) || [];
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onSubmit = useCallback(
    values => {
      dispatch(saveItems(values.items.map(it => {
        const r = { ...it };
        if (r.name !== 'packet') {
          delete r.hasAddressCards;
          delete r.shipmentNumbers;
        } else if (r.hasAddressCards === 'yes') {
          delete r.shipmentNumbers;
        } else {
          r.shipmentNumbers = r.shipmentNumbers.slice(0, r.amount).filter(sn => !!sn);
        }
        return r;
      })));
      dispatch(goNext());
    },
    [dispatch]
  );

  const formProps = { onSubmit, items, translate };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>
      <Styled.h1 sx={{ color: 'secondary' }}>{translate('pickupOrder.items.title')}</Styled.h1>
      <PickupItemsFormik {...formProps} />
    </FullHeightColumn>
  );
}
