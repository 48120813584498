/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui';
import { Field, Form } from 'formik';
import React, { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';

import Button from '../components/Button';
import CheckboxField from '../components/CheckboxField';
import FullHeightColumn from '../components/FullHeightColumn';
import FormField from '../components/FormField';
import AutocompleteStreetField from '../components/AutocompleteStreetField';
import PostalCodeAndCityFields from '../components/PostalCodeAndCityFields';
import { goNext, goBack, saveContactInfo, setPossiblePickupTimes } from '../state/pickupOrder';
import { showNotification, hideAllNotifications } from '../state/notifications';
import { phoneRegExp, postalCodeRegExp } from '../utils/regExp';
import * as api from '../utils/api';

const ContactForm = ({ values, errors, isSubmitting, handleSubmit }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Form
      sx={{
        width: '100%',
        maxWidth: 640,
      }}
    >
      <FormField name="name" label={translate('pickupOrder.contact.name')} />
      <div style={{ display: 'flex' }}>
        <div sx={{ flexGrow: 1, mr: 3, zIndex: 3 }}>
          <AutocompleteStreetField label={translate('address.street')} country="FIorAX" />
        </div>
        <div sx={{ minWidth: '25%', ml: 3 }}>
          <FormField name="apartment" label={translate('address.apartment')} />
        </div>
      </div>
      <PostalCodeAndCityFields country="FIorAX" />
      <FormField name="contactName" label={translate('pickupOrder.contact.name2')} />
      <FormField name="phone" label={translate('pickupOrder.contact.phone')} />
      <FormField name="email" label={translate('pickupOrder.contact.email')} />
      <Field
        component={CheckboxField}
        name="altEmailEnabled"
        label={
          <div>
            <span>{translate('pickupOrder.contact.extraEmail')}</span>
            {values.altEmailEnabled && (
              <FormField sx={{ mt: 2 }} name="altEmail" placeholder={translate('pickupOrder.contact.write')} />
            )}
          </div>
        }
      />

      <Flex sx={{ justifyContent: ['flex-end', null, 'flex-start'], mt: 4 }}>
        <Button type="submit" disabled={isSubmitting} onClick={handleSubmit}>
          {translate('pickupOrder.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const ContactFormik = withFormik({
  displayName: 'ContactForm',
  mapPropsToValues: ({ contact }) => ({
    name: contact.name || '',
    street: contact.street || '',
    apartment: contact.apartment || '',
    postcode: contact.postcode || '',
    city: contact.city || '',
    contactName: contact.contactName || '',
    phone: contact.phone || '',
    email: contact.email || '',
    altEmailEnabled: !!contact.altEmail,
    altEmail: contact.altEmail || '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      name: yup.string().required(translate('form.requiredField')),
      street: yup.string().required(translate('form.requiredField')),
      apartment: yup.string(),
      postcode: yup
        .string()
        .required(translate('form.requiredField'))
        .matches(postalCodeRegExp, translate('form.invalidPostcode')),
      city: yup.string().required(translate('form.requiredField')),
      contactName: yup.string(),
      phone: yup
        .string()
        .required(translate('form.requiredField'))
        .matches(phoneRegExp, translate('form.invalidPhoneNumber')),
      email: yup
        .string()
        .email(translate('form.invalidEmail'))
        .required(translate('form.requiredField')),
      altEmailEnabled: yup.bool(),
      altEmail: yup.string().email(translate('form.invalidEmail')),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
})(ContactForm);

export default function Company() {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const contact = useSelector(state => state.pickupOrder.contact) || {};
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);

  const onSubmit = useCallback(
    async values => {
      dispatch(hideAllNotifications());
      dispatch(saveContactInfo({ ...values, altEmail: values.altEmailEnabled ? values.altEmail : null }));
      try {
        const response = await api.getPickupTimes(values.postcode);
        dispatch(setPossiblePickupTimes(response?.availableTimes, response));
        dispatch(goNext());
      } catch (error) {
        dispatch(showNotification('pickupOrder.contact.postalCodeError'));
      }
    },
    [dispatch]
  );

  const formProps = { onSubmit, contact, translate };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>
      <Styled.h1>{translate('pickupOrder.contact.title')}</Styled.h1>
      <ContactFormik {...formProps} />
    </FullHeightColumn>
  );
}
