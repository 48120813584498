/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { Form } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import FormField from '../components/FormField';

export default function ContractNumberForm(props) {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Form>
      <FormField
        name="contractNumber"
        label={<span sx={{ fontWeight: 'medium' }}>{translate('pickupOrder.contractNumber.number')}</span>}
      />
      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 3,
        }}
      >
        <Button type="submit" onClick={props.handleSubmit} disabled={props.isSubmitting}>
          {translate('pickupOrder.continue')}
        </Button>
      </Flex>
    </Form>
  );
}
