/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useCallback } from 'react';
import { Form, Field, FieldArray, useFormikContext } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Select } from '../components/Select';
import FormField from '../components/FormField';
import Button from '../components/Button';
import { InfoIcon } from '../components/Icon';
import IntegerField from '../components/IntegerField';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';

const PickupItem = ({ namePrefix, item, itemErrors, removeItem, addShipmentNumber }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const itemNames = useSelector(state => state.pickupOrder.possibleItemNames);
  const isPacketType = item.name === 'packet';

  const onAmountChange = useCallback(
    amount => {
      for (let i = item.shipmentNumbers.length; i < amount; i++) {
        addShipmentNumber();
      }
    },
    [item.shipmentNumbers, addShipmentNumber]
  );

  const indices = [];
  if (item.hasAddressCards === 'no') {
    for (let i = 0; i < item.amount; i++) {
      indices.push(i);
    }
  }

  return (
    <>
      <Flex>
        <div>
          <FormField name={`${namePrefix}.name`} as={Select} lowProfile={true}>
            {itemNames.map(name => (
              <option key={name} value={name}>
                {translate(`pickupOrder.items.name.${name}`)}
              </option>
            ))}
          </FormField>
          <Flex>
            <FormField
              name={`${namePrefix}.amount`}
              type="number"
              min={0}
              step={1}
              placeholder={translate('pickupOrder.items.amount')}
              component={IntegerField}
              onChange={onAmountChange}
              lowProfile={true}
            />
            <div sx={{ mb: 24, ml: 24, alignSelf: 'center' }}>{translate('pickupOrder.pieces')}</div>
          </Flex>
          <Flex>
            <FormField
              name={`${namePrefix}.weight`}
              type="number"
              min={0}
              step={5}
              placeholder={translate('pickupOrder.items.weight')}
              component={IntegerField}
              lowProfile={true}
            />
            <div sx={{ mb: 24, ml: 24, alignSelf: 'center' }}>kg</div>
          </Flex>
        </div>
        {removeItem && (
          <Button
            sx={{ alignSelf: 'flex-start', padding: '2', marginLeft: '3' }}
            variant="plain"
            type="button"
            onClick={removeItem}
          >
            {translate('pickupOrder.items.remove')}
          </Button>
        )}
      </Flex>
      {isPacketType ? (
        <>
          <div>{translate('pickupOrder.items.addressCards')}</div>
          <RadioFieldGroup id={`${namePrefix}.hasAddressCards`} error={itemErrors.hasAddressCards}>
            {['yes', 'no'].map(value => (
              <Field
                key={value}
                sx={{ my: 0 }}
                component={RadioField}
                name={`${namePrefix}.hasAddressCards`}
                id={`${namePrefix}.${value}`}
                value={value}
                label={<span sx={{ alignSelf: 'center' }}>{translate(`pickupOrder.items.${value}`)}</span>}
              />
            ))}
          </RadioFieldGroup>

          {indices.length > 0 && (
            <div sx={{ mt: 3 }}>
              <div sx={{ mb: 2 }}>{translate(`pickupOrder.items.codes`)}</div>
              <div sx={{ width: '260px' }}>
                {indices.map(index => (
                  <FormField
                    key={index}
                    sx={{ my: 0, py: 0 }}
                    name={`${namePrefix}.shipmentNumbers.${index}`}
                    placeholder={'Lähetystunnus tai aktivointikoodi'}
                    lowProfile={true}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div>{translate('pickupOrder.items.note')}</div>
      )}
    </>
  );
};

const PickupItemWrapper = ({ itemIndex, removeItem }) => {
  const { values, errors } = useFormikContext();
  const namePrefix = `items.${itemIndex}`;
  const item = values.items[itemIndex];
  const itemErrors = (errors.items || [])[itemIndex] || {};
  const itemProps = {
    namePrefix,
    item,
    itemErrors,
    removeItem: itemIndex !== 0 && removeItem,
  };
  return (
    <FieldArray
      name={`${namePrefix}.shipmentNumbers`}
      render={arrayHelpers => <PickupItem {...itemProps} addShipmentNumber={() => arrayHelpers.push('')} />}
    />
  );
};

export default function PickupItemsForm({ handleSubmit, values: { items }, errors }) {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <div>
      <Form
        sx={{
          width: '100%',
          maxWidth: 640,
        }}
      >
        <FieldArray
          name="items"
          render={arrayHelpers => (
            <>
              {Array.isArray(items) &&
                items.map((item, index) => (
                  <PickupItemWrapper
                    key={index}
                    itemIndex={index}
                    isFirst={index === 0}
                    removeItem={() => arrayHelpers.remove(index)}
                  />
                ))}
              <Flex sx={{ justifyContent: ['flex-end', null, 'flex-start'], mt: 4 }}>
                <Button type="submit" onClick={handleSubmit}>
                  {translate('pickupOrder.continue')}
                </Button>
                <Button
                  sx={{ ml: 3, alignSelf: 'flex-start' }}
                  variant="secondary"
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      name: 'packet',
                      amount: '',
                      weight: '',
                      shipmentNumbers: [],
                    })
                  }
                >
                  {translate('pickupOrder.items.add')}
                </Button>
              </Flex>
            </>
          )}
        />
      </Form>
      <Flex sx={{ mt: 4 }}>
        <InfoIcon />
        <span sx={{ ml: 3 }}>{translate('pickupOrder.items.note2')}</span>
      </Flex>
    </div>
  );
}
