/** @jsx jsx */
import React, { useCallback, useEffect } from 'react';
import { jsx, Flex, Textarea } from 'theme-ui';
import { Form } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import FormField from '../components/FormField';
import { DateField } from '../components/DateField';
import { Select } from '../components/Select';

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const defPickupTime = '08:00-16:00';

const date2dayString = date => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).substr(-2);
  const day = ('0' + date.getDate()).substr(-2);
  return `${year}-${month}-${day}`;
};

const OldForm = ({ handleSubmit, values, setFieldValue }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const possibleDays = useSelector(state => {
    const { possibleTimes } = state.pickupOrder;
    return Object.keys(possibleTimes).filter(
      time => !['holidays', 'allowToday'].includes(time) && possibleTimes[time].length > 0
    );
  });
  const holidays = useSelector(state => state.pickupOrder.possibleTimes.holidays || []);
  const allowToday =
    useSelector(state => state.pickupOrder.possibleTimes.allowToday || false) && new Date().getHours() <= 10;
  const allowTomorrow =
    useSelector(state => state.pickupOrder.possibleTimes.allowToday || false) || new Date().getHours() <= 17;
  const todayIsSelected = (values.date && new Date(values.date).toDateString() === new Date().toDateString()) || false;
  const possiblePickupTimes = useSelector(state => {
    const date = values.date && new Date(values.date);
    if (!date) {
      return [];
    }
    const weekday = weekdays[date.getDay()];
    const times = state.pickupOrder.possibleTimes[weekday] || [];
    if (!todayIsSelected) {
      return times;
    } else {
      return times.filter(t => +t.substr(0, 2) >= 12);
    }
  });
  const dateFilter = useCallback(
    date => !holidays.includes(date2dayString(date)) && possibleDays.includes(weekdays[date.getDay()]),
    [holidays, possibleDays]
  );

  const minDate = new Date();
  if (!allowTomorrow) {
    minDate.setDate(minDate.getDate() + 2);
  } else if (!allowToday) {
    minDate.setDate(minDate.getDate() + 1);
  }

  useEffect(() => {
    if (
      possiblePickupTimes.includes(values.timeRequest) ||
      (!todayIsSelected && values.timeRequest === defPickupTime)
    ) {
      return;
    }
    setFieldValue('timeRequest', !todayIsSelected ? defPickupTime : possiblePickupTimes[0]);
  }, [todayIsSelected, possiblePickupTimes, values.timeRequest, setFieldValue]);

  return (
    <Form
      sx={{
        width: '100%',
        maxWidth: 640,
      }}
    >
      <div sx={{ mb: 3 }}>{translate('pickupOrder.time.note' + (allowToday ? '1' : '2'))}</div>
      <div sx={{ zIndex: 2, position: 'relative' }}>
        <FormField
          zIndex={5}
          name="date"
          component={DateField}
          minDate={minDate}
          disabledKeyboardNavigation
          dateFormat="dd.MM.yyyy"
          filterDate={dateFilter}
          placeholderText={translate('pickupOrder.time.date')}
        />
      </div>
      <FormField
        sx={{ background: 'white', width: 194 }}
        as={Select}
        name="timeRequest"
        label={translate('pickupOrder.time.timeRequest')}
        disabled={possiblePickupTimes.length === 0}
      >
        {!todayIsSelected && (
          <option defaultValue key={defPickupTime} value={defPickupTime}>
            {defPickupTime}
          </option>
        )}
        {possiblePickupTimes.map(pt => (
          <option key={pt} value={pt}>
            {pt}
          </option>
        ))}
      </FormField>
      <FormField
        as={Textarea}
        rows={4}
        name="additionalInfo"
        label={translate('pickupOrder.time.additionalInfo')}
        maxLength={1000}
      />
      <Flex sx={{ justifyContent: ['flex-end', null, 'flex-start'], mt: 3 }}>
        <Button type="submit" onClick={handleSubmit}>
          {translate('pickupOrder.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const NewForm = ({ handleSubmit, values, setFieldValue }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const availableTimes = useSelector(state => state.pickupOrder.availableTimes);
  const dateFilter = useCallback(date => !!availableTimes[date2dayString(date)], [availableTimes]);
  const selectedDate = values.date && date2dayString(new Date(values.date));
  const possiblePickupTimes = availableTimes[selectedDate];

  useEffect(() => {
    const times = possiblePickupTimes || [];
    if (times.includes(values.timeRequest)) {
      return;
    }
    setFieldValue('timeRequest', times[0]);
  }, [possiblePickupTimes, values.timeRequest, setFieldValue]);

  return (
    <Form
      sx={{
        width: '100%',
        maxWidth: 640,
      }}
    >
      <div sx={{ zIndex: 2, position: 'relative' }}>
        <FormField
          zIndex={5}
          name="date"
          component={DateField}
          disabledKeyboardNavigation
          dateFormat="dd.MM.yyyy"
          filterDate={dateFilter}
          placeholderText={translate('pickupOrder.time.date')}
        />
      </div>
      <FormField
        sx={{ background: 'white', width: 194 }}
        as={Select}
        name="timeRequest"
        label={translate('pickupOrder.time.timeRequest')}
        disabled={!possiblePickupTimes || possiblePickupTimes.length === 0}
      >
        {(possiblePickupTimes || []).map(pt => (
          <option key={pt} value={pt}>
            {pt}
          </option>
        ))}
      </FormField>
      <FormField
        as={Textarea}
        rows={4}
        name="additionalInfo"
        label={translate('pickupOrder.time.additionalInfo')}
        maxLength={1000}
      />
      <Flex sx={{ justifyContent: ['flex-end', null, 'flex-start'], mt: 3 }}>
        <Button type="submit" onClick={handleSubmit}>
          {translate('pickupOrder.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

export default function PickupTimeForm(props) {
  const availableTimes = useSelector(state => state.pickupOrder.availableTimes);
  if (availableTimes) {
    return <NewForm {...props} />;
  } else {
    return <OldForm {...props} />;
  }
}
