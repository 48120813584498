import React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import { Box, Styled } from 'theme-ui';
import * as yup from 'yup';

import { goNext, goBack, savePickupTime } from '../state/pickupOrder';

import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import PickupTimeForm from './PickupTimeForm';
import { formatDate } from '../utils/formatters';

const PickupTimeFormik = withFormik({
  displayName: 'PickupTimeForm',
  mapPropsToValues: ({ pickupTime }) => Object.assign({
    date: pickupTime.date || '',
    timeRequest: pickupTime.timeRequest || '08:00-16:00',
    additionalInfo: pickupTime.additionalInfo || ''
  }),
  validationSchema: ({ translate }) => yup.object().shape({
    date: yup.string().nullable().required(translate('form.requiredField')),
    timeRequest: yup.string(),
    additionalInfo: yup.string().max(255, translate('form.tooLong', { len: 255 })),
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  }
})(PickupTimeForm);

export default function PickupTime() {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const pickupTime = useSelector(state => state.pickupOrder.pickupTime);
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const onSubmit = useCallback(values => {
    dispatch(savePickupTime({...values, formattedDate: formatDate(values.date)}));
    dispatch(goNext());
  }, [dispatch]);
  
  const formProps = { onSubmit, pickupTime, translate };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>
      <Styled.h1 sx={{ color: 'secondary' }}>
        {translate('pickupOrder.time.title')}
      </Styled.h1>
      <PickupTimeFormik {...formProps}/>
    </FullHeightColumn>
  );
}
