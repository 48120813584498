/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui';
import React, { useEffect, useRef } from 'react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { showNotification } from '../state/notifications';
import { goNext, goBack } from '../state/pickupOrder';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import getLanguage from '../utils/getLanguage';
import * as streetAndApartment from '../utils/streetAndApartment';

import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import RecaptchaButton from '../components/RecaptchaButton';

export default function ConfirmOrder() {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const pickupOrder = useSelector(state => state.pickupOrder);
  const { contractNumber, contact, pickupTime, items } = pickupOrder;
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);
  const [isSubmitting, setSubmitting] = useState(false);
  const language = useSelector(state => getLanguage(state));

  const componentIsMounted = useRef(null);
  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  });

  const handleSubmit = useCallback(
    async recaptcha => {
      if (!recaptcha) return;

      setSubmitting(true);
      try {
        const itemsTranslated = items.map(item => ({
          ...item,
          type: item.name,
          name: translate(`pickupOrder.items.name.${item.name}`),
        }));
        await api.sendPickupOrder({
          ...pickupOrder,
          items: itemsTranslated,
          recaptcha,
          language,
        });
        dispatch(goNext());
      } catch (error) {
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error('Failed to send pickup order:', error);
        dispatch(showNotification('pickupOrder.confirm.failed'));
      }
      if (componentIsMounted.current) {
        setSubmitting(false);
      }
    },
    [dispatch, pickupOrder, language, items, translate]
  );

  const captchaProps = {
    translate,
    isSubmitting,
    setFieldValue: (_, recaptcha) => handleSubmit(recaptcha),
    buttonText: 'pickupOrder.confirm.button',
  };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>
      <Styled.h1>{translate('pickupOrder.confirm.title')}</Styled.h1>
      <p>
        {translate('pickupOrder.confirm.number')}: {contractNumber}
        <br />
        {contact.name}
        <br />
        {streetAndApartment.combine(contact)}, {contact.postcode} {contact.city}
      </p>
      <p>
        {contact.contactName && <>{contact.contactName}, </>}
        {contact.phone}
        <br />
        {contact.email}
        {contact.altEmail && (
          <>
            <br />
            {contact.altEmail}
          </>
        )}
      </p>
      <p>
        {translate('pickupOrder.confirm.date')}:{' '}
        {pickupTime.date &&
          `${pickupTime.date.getDate()}.${pickupTime.date.getMonth() + 1}.${pickupTime.date.getFullYear()}`}
        <br />
        {translate('pickupOrder.confirm.time')}: {pickupTime.timeRequest}
        <br />
        {pickupTime.additionalInfo}
      </p>
      <dl>
        {items.map((item, index) => (
          <div key={index}>
            {translate(`pickupOrder.items.name.${item.name}`)} {item.amount} {translate('pickupOrder.pieces')},{' '}
            {item.weight} kg
            {(item.shipmentNumbers || []).map((sn, ix) => (
              <div key={ix}>
                {translate('pickupOrder.confirm.code')}: {sn}
              </div>
            ))}
            {item.shipmentNumbers && <br />}
          </div>
        ))}
      </dl>
      <Flex sx={{ flexDirection: 'column', alignItems: ['flex-end', null, 'flex-start'], mt: 3, ml: '-32px' }}>
        <RecaptchaButton {...captchaProps} />
      </Flex>
    </FullHeightColumn>
  );
}
